html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

main {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.disable-scroll {
  position: relative;
  overflow: hidden;
  /* height: 100vh; */
  /* position: fixed; */
  /* left: 0; */
  /* top: 0; */
  width: 100%;
}

.graph-modal {
  --transition-time: 0.3s;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition-time), visibility var(--transition-time);
}

.graph-modal::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

.graph-modal__container {
  position: relative;
  width: 1000px;
  margin: 50px auto;
  display: inline-block;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  cursor: default;
  text-align: left;
  display: none;
}

@media (max-width: 1024px) {
  .graph-modal__container {
    width: 90%;
  }
}
.graph-modal__close {
  position: absolute;
  right: 10px;
  top: 10px;
  border: none;
  padding: 0;
  width: 20px;
  height: 20px;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 512.001 512.001' style='enable-background:new 0 0 512.001 512.001;' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717 L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859 c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287 l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285 L284.286,256.002z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  cursor: pointer;
}

.graph-modal.is-open {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition-time), visibility var(--transition-time);
}

.graph-modal__container.graph-modal-open {
  display: inline-block;
}

.fade {
  opacity: 0;
  transition: opacity var(--transition-time);
}

.fade.animate-open {
  opacity: 1;
  transition: opacity var(--transition-time);
}

.fadeInUp {
  opacity: 0;
  transform: translateY(-100px);
  transition: transform var(--transition-time), opacity var(--transition-time);
}

.fadeInUp.animate-open {
  opacity: 1;
  transform: translateY(0);
  transition: transform var(--transition-time), opacity var(--transition-time);
}